<template>
  <div class="withdraw-management">
    <el-card class="box-card">
      <!-- 搜索栏 -->
      <div class="search-bar">
        <el-form :inline="true" :model="searchForm" class="form-inline">
          <el-form-item label="用户ID">
            <el-input v-model="searchForm.userId" placeholder="请输入用户ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="订单号">
            <el-input v-model="searchForm.orderNo" placeholder="请输入订单号" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="searchForm.status" placeholder="请选择状态" clearable>
              <el-option label="待审核" value="0"></el-option>
              <el-option label="已通过" value="1"></el-option>
              <el-option label="已拒绝" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间范围">
            <el-date-picker
              v-model="searchForm.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 提现记录表格 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column prop="orderNo" label="订单号" width="180"></el-table-column>
        <el-table-column prop="userId" label="用户ID" width="100"></el-table-column>
        <el-table-column prop="username" label="用户名" width="120"></el-table-column>
        <el-table-column prop="amount" label="提现金额" width="120">
          <template slot-scope="scope">
            {{ scope.row.amount.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="bankInfo" label="银行卡信息" width="200">
          <template slot-scope="scope">
            <div>{{ scope.row.bankName }}</div>
            <div>{{ scope.row.bankCard }}</div>
            <div>{{ scope.row.bankHolder }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.status)">
              {{ getStatusText(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="申请时间" width="160">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="handleTime" label="处理时间" width="160">
          <template slot-scope="scope">
            {{ formatDate(scope.row.handleTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 0"
              size="mini"
              type="success"
              @click="handleApprove(scope.row)"
            >通过</el-button>
            <el-button
              v-if="scope.row.status === 0"
              size="mini"
              type="danger"
              @click="handleReject(scope.row)"
            >拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 拒绝原因对话框 -->
    <el-dialog
      title="拒绝提现"
      :visible.sync="rejectDialogVisible"
      width="400px"
    >
      <el-form :model="rejectForm" ref="rejectForm" :rules="rejectRules">
        <el-form-item label="拒绝原因" prop="reason" label-width="80px">
          <el-input
            type="textarea"
            v-model="rejectForm.reason"
            :rows="3"
            placeholder="请输入拒绝原因"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rejectDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitReject" :loading="submitting">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'WithdrawManagement',
  data() {
    return {
      searchForm: {
        userId: '',
        orderNo: '',
        status: '',
        dateRange: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      rejectDialogVisible: false,
      submitting: false,
      rejectForm: {
        orderId: '',
        reason: ''
      },
      rejectRules: {
        reason: [
          { required: true, message: '请输入拒绝原因', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const params = {
          page: this.currentPage,
          pageSize: this.pageSize,
          ...this.searchForm,
          startDate: this.searchForm.dateRange?.[0],
          endDate: this.searchForm.dateRange?.[1]
        }
        const res = await this.$http.get('/api/admin/withdraw/records', { params })
        if (res.data.code === 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      } catch (error) {
        console.error('获取提现记录失败:', error)
        this.$message.error('获取提现记录失败')
      } finally {
        this.loading = false
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return '-'
      const date = new Date(timestamp)
      return date.toLocaleString()
    },
    getStatusType(status) {
      const types = {
        0: 'warning', // 待审核
        1: 'success', // 已通过
        2: 'danger'   // 已拒绝
      }
      return types[status] || 'info'
    },
    getStatusText(status) {
      const statuses = {
        0: '待审核',
        1: '已通过',
        2: '已拒绝'
      }
      return statuses[status] || status
    },
    handleSearch() {
      this.currentPage = 1
      this.fetchData()
    },
    resetSearch() {
      this.searchForm = {
        userId: '',
        orderNo: '',
        status: '',
        dateRange: []
      }
      this.handleSearch()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetchData()
    },
    async handleApprove(row) {
      try {
        await this.$confirm('确认通过该提现申请?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const res = await this.$http.post(`/api/admin/withdraw/${row.orderNo}/approve`)
        if (res.data.code === 200) {
          this.$message.success('操作成功')
          this.fetchData()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('审核失败:', error)
          this.$message.error('操作失败')
        }
      }
    },
    handleReject(row) {
      this.rejectForm.orderId = row.orderNo
      this.rejectDialogVisible = true
    },
    async submitReject() {
      try {
        await this.$refs.rejectForm.validate()
        this.submitting = true
        const res = await this.$http.post(`/api/admin/withdraw/${this.rejectForm.orderId}/reject`, {
          reason: this.rejectForm.reason
        })
        if (res.data.code === 200) {
          this.$message.success('操作成功')
          this.rejectDialogVisible = false
          this.fetchData()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('拒绝失败:', error)
          this.$message.error('操作失败')
        }
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style scoped>
.withdraw-management {
  padding: 20px;
}
.search-bar {
  margin-bottom: 20px;
}
.pagination-container {
  margin-top: 20px;
  text-align: right;
}
</style> 